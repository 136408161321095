<template>
  <div>
    <div class="card mt-4" v-if="isAllCompleted">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="py-2 mb-0">
          <fa-icon :icon="['fa', 'print']" class="mr-3 text-secondary" />Cetak
          Bukti Pendaftaran
        </h6>
        <div v-if="isActivatedVA">
          <button class="btn btn-sm btn-link" @click="download">
            Download
          </button>
        </div>
      </div>
      <div class="card-body p-0" style="position: relative">
        <div v-if="isActivatedVA">
          <iframe v-auth-image.nocache="`/pendaftar/${$psb.TokenParser.user().uuid}/exportpdf`
            " frameborder="0">
            Tidak dapat menampilkan berkas.
            <a href="javascript:void(0)" @click="download">Klik disini</a> untuk
            mendownload.
          </iframe>
          <div class="cetak-loading text-center">
            <fa-icon class="text-secondary" :icon="['fas', 'spinner']" pulse />
            <p class="pt-4 text-secondary">Memuat...</p>
          </div>
        </div>

        <div v-else class="alert alert-warning m-3" role="alert">
          Akun Virtual Bank (VA) masih belum aktif.
          <br />
          Silahkan pilih metode pembayaran pada menu pembayaran untuk mengaktifkan VA.
          <br />
          <br />
          Apabila kesulitan dalam membayar, silahkan kontak bagian keuangan.
        </div>
      </div>
    </div>
    <div v-else class="mt-4">
      <div class="alert alert-danger" role="alert">
        <strong>Tidak dapat mencetak karena data belum lengkap terisi,
          diantaranya:</strong>
        <ul style="list-style-type: disc; padding-left: 1rem">
          <li v-if="!isFotodiriCompleted">Foto diri</li>
          <li v-if="!isKeluargaCompleted">Data orang tua / wali</li>
          <li v-if="isKeluargaCompleted && !isIbuKandungCompleted">
            Ibu Kandung harus diisi
          </li>
          <li v-if="!isBerkasCompleted">
            Scan berkas Asli (bukan fotokopi)
            <ul style="list-style-type: '- '; padding-left: 1rem">
              <li v-if="!hasBerkasAkta">Scan akte kelahiran peserta didik</li>
              <li v-if="!hasBerkasKK">Scan kartu keluarga (KK)</li>
              <li v-if="!hasBerkasKTP">Scan KTP ortu/wali</li>
              <li>
                Scan ijazah terakhir / SHUN
                <span class="font-italic">(opsional jika sudah ada)</span>
              </li>
            </ul>
          </li>
          <li v-if="!isMutasiBerkasCompleted">
            Karena jenis daftar MUTASI, lengkapi juga dengan berkas<br />
            <ul style="list-style-type: '- '; padding-left: 1rem">
              <li>Surat Keterangan Menerima dari Sekolah Tujuan</li>
            </ul>
          </li>
        </ul>
      </div>
      <router-link :to="navTo" class="font-weight-bold">
        <button class="btn btn-sm btn-primary">Klik untuk melengkapi</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["biodatas"],
  data() {
    return {
      biodata: {},
      hasBerkasKK: false,
      hasBerkasKTP: false,
      hasBerkasAkta: false,
      hasBerkasKeteranganMenerima: false,
    };
  },
  watch: {
    biodatas: {
      handler() {
        this.biodata = this.biodatas;

        if (Array.isArray(this.biodata.berkas)) {
          for (let i = 0, len = this.biodata.berkas.length; i < len; i++) {
            const idKatImg = this.biodata.berkas[i].id_kategori_image;
            if (idKatImg === 1) {
              this.hasBerkasKK = true;
            } else if (idKatImg === 2) {
              this.hasBerkasKTP = true;
            } else if (idKatImg === 3) {
              this.hasBerkasAkta = true;
            } else if (idKatImg === 22) {
              this.hasBerkasKeteranganMenerima = true;
            }
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.biodata = this.biodatas || {};
  },
  computed: {
    navTo() {
      let nav = "/";
      if (!this.isKeluargaCompleted || !this.isIbuKandungCompleted) {
        nav = "/ortuwali";
      } else if (!this.isBerkasCompleted || !this.isFotodiriCompleted) {
        nav = "/berkas";
      }
      return nav;
    },
    isActivatedVA() {
      return this.biodata.pesertadidik.is_activated_va === "Y" || this.biodata.pembayaran.biaya_pendaftaran <= 0;
    },
    isAllCompleted() {
      return (
        this.isFotodiriCompleted &&
        this.isKeluargaCompleted &&
        this.isIbuKandungCompleted &&
        this.isBerkasCompleted &&
        this.isMutasiBerkasCompleted
      );
    },
    isFotodiriCompleted() {
      return (
        this.biodata.fotodiri &&
        this.biodata.fotodiri.normal &&
        this.biodata.fotodiri.normal !== "/img/default/default_no_face_n.jpg"
      );
    },
    isKeluargaCompleted() {
      return this.biodata.keluarga && this.biodata.keluarga.length > 0;
    },
    isIbuKandungCompleted() {
      // ini diperlukan karena pembuatan rekening bank butuh nama ibu kandung
      let completed = false;
      if (this.isKeluargaCompleted) {
        for (let i = 0, len = this.biodata.keluarga.length; i < len; i++) {
          let relasiKeluarga = this.biodata.keluarga[i].relasi_keluarga;
          if (relasiKeluarga.kode_relasi === "12Y") {
            completed = true;
          }
        }
      }
      return completed;
    },
    isBerkasCompleted() {
      return this.hasBerkasKK && this.hasBerkasKTP && this.hasBerkasAkta;
    },
    isMutasiBerkasCompleted() {
      const isMutasi =
        this.biodata.pesertadidik &&
        this.biodata.pesertadidik.jenis_daftar === "MUTASI";

      if (!isMutasi) {
        return true;
      }

      return isMutasi && this.hasBerkasKeteranganMenerima;
    },
  },
  methods: {
    download() {
      this.$axios({
        url: `/pendaftar/${this.$psb.TokenParser.user().uuid}/exportpdf`,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          // const date = new Date();
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            (res.headers["x-content-disposition"] || "").split("filename=")[1]
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => { });
    },
  },
};
</script>

<style>
iframe {
  width: 100%;
  height: 100vh;
}
</style>
